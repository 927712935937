import React from "react"
import { graphql } from "gatsby"
import "@styles/disclosure.scss"
import { SanityDisclosure } from "@utils/globalTypes"
import { BaseBlockContent } from "@utils/richText"
import SEO from "@components/SEO"
import { Container } from "react-bootstrap"

type Props = {
  data: {
    sanityDisclosure: SanityDisclosure
  }
  location: Location
}

const DisclosurePage = ({ data: { sanityDisclosure }, location }: Props) => {
  const { title, 
          backgroundColor, 
          _rawContent, 
          disclosureStatement, 
          privacyPolicy, 
          relationshipSummary  } = sanityDisclosure
  const metadata = {
    location: location.href,
    ...sanityDisclosure?.seo,
  }

  let textBlock = []
  _rawContent.forEach(content => textBlock.push(content.children[0].text))
  const compText = _rawContent[2].children[2].text
  const relationshipSummaryTitle = _rawContent[1].children[1].text
  const disclosureStatementTitle = _rawContent[2].children[1].text
  const privacyPolicyTitle = _rawContent[3].children[1].text
  const disclosureStatementUrl = disclosureStatement.asset.url
  const privacyPolicyUrl = privacyPolicy.asset.url
  const relationshipSummaryUrl = relationshipSummary.asset.url
  
  return (
    <>
      <SEO {...metadata} />
      <section
        className="content-padding-top disclosure-wrapper"
        style={{ backgroundColor: backgroundColor || "#DED9E5" }}
      >
        <Container>
          <h2>{textBlock[0]}</h2>
          <p>{textBlock[1]}
            <a 
              href={relationshipSummaryUrl} 
              target="_blank" 
              rel="noopener" 
              className="link">
              {relationshipSummaryTitle}
            </a>
          </p>
          <p>{textBlock[2]}
            <a
              href={disclosureStatementUrl}
              target="_blank"
              rel="noopener"
              className="link">
              {disclosureStatementTitle}
            </a>
            {compText}
          </p>
          <p>
            <strong>{textBlock[3]}
              <a 
                href={privacyPolicyUrl} 
                target="_blank" 
                rel="noopener" 
                className="link">
                {privacyPolicyTitle}
              </a>
            </strong>
          </p>
        </Container>
      </section>
    </>
  )
}

export default DisclosurePage

export const query = graphql`
  query {
    sanityDisclosure(_id: { in: ["drafts.disclosurePage", "disclosurePage"] }) {
      seo {
        description
        keywords
        image
        title
        twitterCard
        twitterCreator
        twitterSite
      }
      title
      slug {
        current
      }
      backgroundColor
      _rawContent(resolveReferences: { maxDepth: 10 })
      disclosureStatement {
        asset {
          url
        }
      }
      privacyPolicy {
        asset {
          url
        }
      }
      relationshipSummary {
        asset {
          url
        }
      }
    }
  }
`